import React from "react";
import get from "lodash/get";
import Helmet from "react-helmet";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { Link } from "gatsby";

import Slider from "rc-slider";
const Range = Slider.Range;
import "rc-slider/assets/index.css";

import Layout from "../../../components/layout.js";

import Map from "./map";
import "./style.scss";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYW1haG9uIiwiYSI6ImNpcmgzejZyeDAxOTFnNm5yZXVocDNvcjYifQ.HbwFh7tdIgCDvyyBFOHQFA";

Date.fromDayofYear = function (n, y) {
  if (!y) y = new Date().getFullYear();
  var d = new Date(y, 0, 1);
  return new Date(d.setMonth(0, n));
};

class FireMap extends React.Component {
  colors = [
    {
      STAT_CAUSE_DESCR: "Debris Burning",
      STAT_CAUSE_CODE: 5,
      n_occurances: 429028,
      color: "#000004",
    },
    {
      STAT_CAUSE_DESCR: "Miscellaneous",
      STAT_CAUSE_CODE: 9,
      n_occurances: 323805,
      color: "#110A30",
    },
    {
      STAT_CAUSE_DESCR: "Arson",
      STAT_CAUSE_CODE: 7,
      n_occurances: 281455,
      color: "#320A5E",
    },
    {
      STAT_CAUSE_DESCR: "Lightning",
      STAT_CAUSE_CODE: 1,
      n_occurances: 278468,
      color: "#57106E",
    },
    {
      STAT_CAUSE_DESCR: "Missing\\/Undefined",
      STAT_CAUSE_CODE: 13,
      n_occurances: 166723,
      color: "#781C6D",
    },
    {
      STAT_CAUSE_DESCR: "Equipment Use",
      STAT_CAUSE_CODE: 2,
      n_occurances: 147612,
      color: "#9A2865",
    },
    {
      STAT_CAUSE_DESCR: "Campfire",
      STAT_CAUSE_CODE: 4,
      n_occurances: 76139,
      color: "#BC3754",
    },
    {
      STAT_CAUSE_DESCR: "Children",
      STAT_CAUSE_CODE: 8,
      n_occurances: 61167,
      color: "#D84C3E",
    },
    {
      STAT_CAUSE_DESCR: "Smoking",
      STAT_CAUSE_CODE: 3,
      n_occurances: 52869,
      color: "#ED6925",
    },
    {
      STAT_CAUSE_DESCR: "Railroad",
      STAT_CAUSE_CODE: 6,
      n_occurances: 33455,
      color: "#F98C0A",
    },
    {
      STAT_CAUSE_DESCR: "Powerline",
      STAT_CAUSE_CODE: 11,
      n_occurances: 14448,
      color: "#FCB418",
    },
    {
      STAT_CAUSE_DESCR: "Fireworks",
      STAT_CAUSE_CODE: 10,
      n_occurances: 11500,
      color: "#F4DF53",
    },
    {
      STAT_CAUSE_DESCR: "Structure",
      STAT_CAUSE_CODE: 12,
      n_occurances: 3796,
      color: "#FCFFA4",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      year: [1992, 2015],
      day: [1, 365],
    };

    console.log(this.colors);
  }

  updateYear(e) {
    this.setState({
      year: e,
    });
  }

  updateDay(e) {
    this.setState({
      day: e,
    });
  }

  doyToDate(doy) {
    var monthNames = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(2017, 0, doy);
    return monthNames[date.getMonth()] + " " + date.getDay();
  }

  render() {
    return (
      <Layout>
        <div className="fire-map">
          <h2>Fire Map</h2>

          <br />
          <div className="controls-container">
            <div className="legend-container">
              <h3>Legend</h3>
              <table>
                {this.colors.map((color) => {
                  return (
                    <tr className="legend-item">
                      <td>
                        <p>{color["STAT_CAUSE_DESCR"]}</p>
                      </td>
                      <td>
                        <span
                          className="swatch"
                          style={{ backgroundColor: color["color"] }}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            {/* <div className='inputs-container'>
            <h3>Controls</h3>
            <div className='slider-container'>
              <p className='value'>{this.state.year[0]} <span>through</span> {this.state.year[1]}</p>
              <Range
                min={1992}
                max={2015}
                defaultValue={[1992, 2015]}
                vertical={true}
                count={1}
                pushable={true}
                allowCross={false}
                onChange={(e) => this.updateYear(e) }/>
              <p>Year</p>
            </div>

            <div className="inputs-container">
              <h3>Controls</h3>
              <div className="slider-container">
                <p className="value">
                  {this.state.year[0]} <span>through</span> {this.state.year[1]}
                </p>
                <Range
                  min={1992}
                  max={2015}
                  defaultValue={[1992, 2015]}
                  vertical={true}
                  count={1}
                  pushable={true}
                  allowCross={false}
                  onChange={e => this.updateYear(e)}
                />
                <p>Year</p>
              </div>
              <div className="slider-container">
                <p className="value">
                  {this.doyToDate(this.state.day[0])} <span>through</span>{" "}
                  {this.doyToDate(this.state.day[1])}
                </p>
                <Range
                  min={1}
                  max={365}
                  vertical={true}
                  defaultValue={[1, 365]}
                  count={1}
                  pushable={true}
                  allowCross={false}
                  step={1}
                  onChange={e => this.updateDay(e)}
                />
                <p>Day</p>
              </div>
            </div>
          </div> */}
          </div>
          <div className="map-container">
            <Map year={this.state.year} day={this.state.day} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default FireMap;
